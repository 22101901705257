import React, { Component } from "react";


class Screenshot extends Component { 
    render(){
        return (
            <div className="screenshot-area pt--120" id="screenshots">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--80">
                                <h2>APP <span className="theme-color">SCREENSHOTS</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <p>Explore the sleek and intuitive interface of BookBuddy with our screenshot gallery. Get a glimpse of how easy it is to organize your book collection, discover personalized recommendations, and connect with friends. See for yourself why BookBuddy is the ultimate app for book lovers.</p>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="screenshot-carousel" id="screenshot-carousel" data-carousel-3d>
                                <img className="image-1" src={require('../assets/images/app/screenshot-01.png')} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-02.png')} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-03.png')} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-04.png')} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-05.png')} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-06.png')} alt="App Screenshot"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Screenshot;










