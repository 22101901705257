import React ,  { Component } from "react";

class About extends Component{
    render(){
        return(
            <div className={`app-about ${this.props.horizontalabout}`}  id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>ABOUT <span className="theme-color">BookBuddy</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                <p>Welcome to BookBuddy - Your Trusted Companion for All Things Literary!</p>
                                <p>At BookBuddy, we understand the joy and passion that comes with building and nurturing your personal library. That's why we've crafted the ultimate solution to help you organize, discover, and connect with your books like never before.</p>
                                <p>Gone are the days of scattered bookshelves and forgotten titles. With BookBuddy, you can effortlessly digitize your entire collection, creating a dynamic digital library that fits right in your pocket. Our intuitive interface makes it a breeze to sort, categorize, and curate your books, ensuring that every title is easily accessible whenever inspiration strikes.</p>
                                <p>But BookBuddy is more than just a digital bookshelf - it's a gateway to a world of literary exploration and connection. Our smart AI analyzes your reading habits to offer personalized recommendations, guiding you towards new and exciting titles that match your unique tastes. And with the ability to share your library and wishlist with friends, you can foster a vibrant community of book lovers, swapping recommendations and sharing in the joy of discovery together.</p>
                                <p>Whether you're a seasoned bibliophile or just beginning your literary journey, BookBuddy is here to support and inspire you every step of the way. Join us today and unlock the full potential of your reading experience. Welcome to the BookBuddy family!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 offset-lg-1 mt--40">
                            <div className="about-thumbnail mr--35">
                                <img className="image-1" src={require('../assets/images/app/mobile-4.png')} alt="App Landing"/>
                               
                            </div>
                        </div>
                        <div className="col-lg-6 mt--40">
                            <div className="about-content">
                                <h2 className="title">BEST <span className="theme-color">MOBILE APP</span></h2>
                                <p>Discover the unrivaled convenience and sophistication of BookBuddy - the best-in-class app for managing your personal library with ease. Experience seamless organization, personalized recommendations, and effortless sharing like never before. Join the countless readers who trust BookBuddy to enhance their literary journey. Download now and see why we're in a league of our own.</p>
                               
                                <div className="about-buttons">
                                    <a type="button" className="button-default button-olive button-border" role="button" href="#download">Download</a>
                                    <a className="button-default button-border" href="#about" role="button">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











