import React , { Component } from "react";


class Download extends Component{
    render(){
        return(
            <div className={`download-area ${this.props.horizontal}`} id="download">
                <br /><br/><br/><br/>
                <div className="bg-overlay"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--55 pt--50">  
                                <h2 className="text-white">DOWNLOAD OUR <span className="theme-color">LATEST APP</span></h2>
                                
                                <img src={require('../assets/images/app/title-icon-4.png')} alt="App Landing"/>
                                <h3 style={{color: '#fff'}}>Version 1.1.4 - Latest</h3>
                                <p className="text-white">Ready to elevate your reading experience? Download BookBuddy now from the Google Play Store or the Apple App Store and unlock the full potential of your personal library. With just a few taps, you'll gain access to intuitive organization, personalized recommendations, and seamless sharing. Join the BookBuddy community today and take your love for books to new heights.

</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                
                                <button type="button" className="download-btn" onClick={()=>{
                                    console.log("REDIRECTING")
                                    window.location.href = 'https://apps.apple.com/us/app/bookbuddy-digital-library/id6479371172';
                                }} style={{cursor: 'pointer',}}>
                                   
                                    <i className="zmdi zmdi-apple"></i>
                                    <span>Available on the <span className="large-text">App Store</span> </span>
                                    
                                </button>
                                
                                <button type="submit" className="download-btn" onClick={() => {
                                    console.log("REDIRECTING")
                                    window.location.href="https://play.google.com/store/apps/details?id=com.bookbuddy";
                                }}>
                                    <i className="zmdi zmdi-google-play"></i>
                                    <span>Available on the <span className="large-text">Play Store</span> </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br/><br/><br/>
            </div>
        )
    }
}

export default Download;







