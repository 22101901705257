import React from "react";
import Slider from "react-slick"

import {testimonial, testimonial2} from "./script";

class Testimonial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          nav1: null,
          nav2: null
        };
    }

    componentDidMount() {
        this.setState({
          nav1: this.testimonial,
          nav2: this.testimonial2
        });
    }


    render(){
        return(
            <div className="testimonial-wrapper pt--120 text-center" id="reviews">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="testimonial-activation">
                                <div className="section-title text-center mb--80">
                                    <h2>APP <span className="theme-color">REVIEWS</span></h2>
                                    <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                    <p>Discover why BookBuddy has earned rave reviews from book lovers worldwide! Hear what users are saying about our intuitive interface, personalized recommendations, and seamless organization. Join the chorus of satisfied readers who have made BookBuddy their go-to app for managing and enjoying their book collections. Explore the reviews and see why BookBuddy is the must-have app for any bibliophile.</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">

                                        <Slider {...testimonial} asNavFor={this.state.nav2} ref={slider => (this.testimonial = slider)} className="testimonial-image-slider text-center">
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/4.jpg')} alt="testimonial 1" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/5.jpg')} alt="testimonial 1" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/4.jpg')} alt="testimonial 1" />
                                            </div>
                                            <div className="sin-testiImage">
                                                <img src={require('../assets/images/client/5.jpg')} alt="testimonial 1" />
                                            </div>
                                            
                                        </Slider>

                                    </div>
                                </div>

                                <Slider {...testimonial2} asNavFor={this.state.nav1} ref={slider => (this.testimonial2 = slider)} className="testimonial-text-slider text-center">
                                    <div className="sin-testiText">
                                        <h2>G Yuksel </h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                        </div>
                                        <p>BookBuddy has transformed the way I manage my book collection! The interface is incredibly intuitive and user-friendly, making it a breeze to organize and categorize my books. The personalized recommendations have introduced me to so many amazing reads that I wouldn't have discovered otherwise. Plus, the ability to share my library with friends and chat directly within the app adds a whole new level of enjoyment to my reading experience. I can't recommend BookBuddy enough - it's a must-have for any book lover!</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>S Jennings</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>As a busy mom and avid reader, BookBuddy has been a game-changer for me. The ease of scanning ISBN codes to quickly add books to my digital library has saved me so much time. I love how I can easily organize my collection into categories and collections, making it simple to find exactly what I'm looking for. The personalized recommendations have been spot-on, introducing me to new authors and genres that I now can't get enough of. The only reason I didn't give it a full 5 stars is because I wish there were more customization options for organizing my library. Overall, though, BookBuddy has become an essential part of my reading routine!</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>G Yuksel </h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                        </div>
                                        <p>BookBuddy has transformed the way I manage my book collection! The interface is incredibly intuitive and user-friendly, making it a breeze to organize and categorize my books. The personalized recommendations have introduced me to so many amazing reads that I wouldn't have discovered otherwise. Plus, the ability to share my library with friends and chat directly within the app adds a whole new level of enjoyment to my reading experience. I can't recommend BookBuddy enough - it's a must-have for any book lover!</p>
                                    </div>
                                    <div className="sin-testiText">
                                        <h2>S Jennings</h2>
                                        <div className="client-rating">
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star color"></i>
                                            <i className="zmdi zmdi-star-half color"></i>
                                        </div>
                                        <p>As a busy mom and avid reader, BookBuddy has been a game-changer for me. The ease of scanning ISBN codes to quickly add books to my digital library has saved me so much time. I love how I can easily organize my collection into categories and collections, making it simple to find exactly what I'm looking for. The personalized recommendations have been spot-on, introducing me to new authors and genres that I now can't get enough of. The only reason I didn't give it a full 5 stars is because I wish there were more customization options for organizing my library. Overall, though, BookBuddy has become an essential part of my reading routine!</p>
                                    </div>
                                </Slider>
                                    
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
 export default Testimonial;







