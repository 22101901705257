import React ,  { Component } from "react";



class Feature extends Component{
    render(){
       let data = [
           
        {
            icon: 'zmdi zmdi-collection-text',
            title: 'Organize with Ease',
            desc: 'With BookBuddy\'s intuitive user-friendly interface, you can effortlessly manage your digital library. Sort, categorize, and create collections with just a few taps. Say goodbye to the chaos of physical bookshelves!'
        },

        {
            icon: 'zmdi zmdi-sun',
            title: 'Personalized Recommendations',
            desc: 'Our intelligent AI analyzes your current library to offer personalized book recommendations tailored to your tastes. Discover new favorites that align perfectly with your reading preferences.            '
        },

        {
            icon: 'zmdi zmdi-center-focus-weak',
            title: 'Effortless Book Adding',
            desc: 'Say goodbye to manual data entry! Simply scan the ISBN code of your books to swiftly add them to your library. Add thousands of books in minutes and spend more time enjoying your reads.'
        },

        {
            icon: 'zmdi zmdi-accounts-alt',
            title: 'Connect and Share',
            desc: 'Invite friends to join BookBuddy and share your library and wishlist with them. Explore your friends\' collections, discover mutual interests, and even purchase books directly from Amazon with just a tap.'
        },

        {
            icon: 'zmdi zmdi-share',
            title: 'Rate, Share, and Connect',
            desc: 'Did a book leave you spellbound? Rate it, share it, and spread the joy of reading with your friends. Shared books are easily accessible to your friends, who can also purchase them to join in the literary adventure.'
        },

        {
            icon: 'zmdi zmdi-comment-alt-text',
            title: 'Seamless Communication',
            desc: 'Keep the conversation flowing with your book-loving buddies! BookBuddy features built-in messaging functionality, allowing you to chat directly with friends without ever leaving the app.'
        },

        {
            icon: 'zmdi zmdi-shield-security',
            title: 'Total Control',
            desc: 'Worried about unwanted connections? Not a problem. BookBuddy lets you block unwanted friends and keep your profile private for ultimate peace of mind.'
        }
       ] 

       let DataList = data.map((val , i) => {
           return(
               /* Start Single Feature */
               <div className="feature" key={i}>
                   <div className="feature-icon">
                       <i className={`${val.icon}`}/>
                   </div>
                   <div className="content">
                       <h4 className="title">{val.title}</h4>
                       <p className="desc">{val.desc}</p>
                   </div>
               </div>
               /* End Single Feature */
           )
       })

       return(
           <div className= {`feature-area feature-bg-image pb--50 ${this.props.horizontalfeature}`} id="features">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>AWESOME <span className="theme-color">FEATURES</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <p>BookBuddy Elevates Your Reading Experience to New Heights! Discover the array of powerful tools and functionalities that make BookBuddy the ultimate companion for book lovers. From seamless organization to personalized recommendations, explore how BookBuddy enhances every aspect of your literary journey. Unlock a world of possibilities and dive into the features that set BookBuddy apart from the rest.</p>
                            </div>
                       </div>
                   </div>
                   <div className="row mt--30">
                       <div className="col-lg-7 offset-lg-5">
                            <div className="feature-list">
                                {DataList}
                            </div>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default Feature;
