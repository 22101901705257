import React, { Component } from "react";



class Service extends Component{
    render(){
        let data = [
            {
                icon: 'zmdi zmdi-settings',
                title: 'Easy To Start',
                desc: 'Signing up for BookBuddy is as easy as a few taps with your Google or Apple ID. No need to remember another password - simply log in with your existing account and start enjoying the benefits of BookBuddy instantly. Streamlined signup, maximum convenience - only with BookBuddy.'
            },

            {
                icon: 'zmdi zmdi-favorite',
                title: 'User Friendly',
                desc: 'Experience the effortless simplicity of BookBuddy - the user-friendly app designed to make managing your book collection a breeze. With its intuitive interface and seamless navigation, BookBuddy ensures that organizing, sharing, and discovering books has never been easier. Say goodbye to complexity and hello to a smoother, more enjoyable reading experience with BookBuddy.'
            },

            {
                icon: 'zmdi zmdi-time',
                title: '24/7 Support',
                desc: 'Rest easy knowing that BookBuddy offers round-the-clock support, ensuring that assistance is always just a message away. Our dedicated team is here to address any questions or concerns you may have, providing unparalleled support whenever you need it. With BookBuddy, help is available whenever you need it most.'
            }
        ];
        let DataList = data.map((val, i) => {
            return(
                <div className="col-lg-4 service-column" key={i}>
                    <div className="single-service text-center">
                        <div className="service-icon">
                            <i className={`${val.icon}`}></i>
                        </div>
                        <h4 className="title">{val.title}</h4>
                        <p className="desc">{val.desc}</p>
                    </div>
                </div>
            )
        });

        return (
            <div>
                <br/><br/>
                {/* Start Service Area */}
                <div className={`service-area ${this.props.horizontal}`}>
               
                    <div className="container">
                        
                        <div className="row">
                            {DataList}
                            
                        </div>
                    </div>
                    
                </div>
                {/* End Service Area */}
            </div>
        )
    }
}

export default Service;

