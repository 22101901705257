import React, { Component } from "react";


class HeroOlive extends Component {
    
    render(){
        console.log(this.props);
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home"  style={{background:'#000000'}}>
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-7 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    <h1><span className="theme-color" style={{fontFamily: 'Ariq'}}>BOOKBUDDY</span> </h1>
                                    <h4 style={{color: '#ffffff', position: 'relative', top: -25}}>Introducing BookBuddy - Your Ultimate Book Companion!</h4>
                                    
                                    <div className="banner-buttons">
                                        <a type="button" className="button-default button" role="button" href="#download">Download</a>
                                        <a className="button-default button-white" href="#about" role="button">Learn more</a>
                                    </div>
                                </div>
                            </div>

                            <div className="banner-apps">
                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-apple"></i>
                                        <h4>ios</h4>
                                        <h3>(new)</h3>
                                    </div>
                                </div>

                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-cloud-download"></i>
                                        <h4>Downloads</h4>
                                        <h3>(new)</h3>
                                    </div>
                                </div>

                                {/* Start Single app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-android"/>
                                        <h4>Android</h4>
                                        <h3>(new)</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-product-image text-right">
                                <img className="image-1" src={require('../assets/images/banner-mobile.png')} alt="App Landing"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeroOlive;








